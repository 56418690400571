import config from 'config'
import { useForm } from 'formular'
import { required } from 'helpers/validators'
import { useEffect } from 'react'


const useStationForm = (station?: PublicTransport.Station) => {
  const { title, lat = config.cityCenter.lat, lon = config.cityCenter.lng } = station || {}

  const formOpts = {
    fields: {
      title: { validate: [ required ] },
    },
    initialValues: { title },
  }

  const mapFormOpts = {
    fields: {
      placemarks: {},
    },
    initialValues: {
      placemarks: [ { id: 0, geometry: [ lat, lon ], editOn: true } ] as EditableMap.Placemark[],
    },
  }

  const form = useForm<PublicTransport.StationForm>(formOpts, [ title ])
  const mapForm = useForm<EditableMap.MapForm>(mapFormOpts, [ lat, lon ])

  useEffect(() => {
    // when any of the form's attached fields is changed, we are setting form's 'isChanged' to 'true'
    // because it is not setting by itself and form remains 'unchanged'
    mapForm.on('change', () => mapForm.setState({ isChanged: true }))
    form.on('change', () => form.setState({ isChanged: true }))
  }, [ form, mapForm ])

  return { form, mapForm }
}


export default useStationForm
