import { useMemo } from 'react'
import { useQuery } from 'hooks'


const useForecast = (stationId) => {
  const queryOptions = useMemo(() => ({
    url: `/transport/rest/bus/forecast/${stationId}`,
  }), [ stationId ])

  const { data: forecast, errors: forecastErrors,
    isFetching: isForecastFetching, fetch } = useQuery<PublicTransport.Forecast>(queryOptions)

  return {
    forecast,
    forecastErrors,
    isForecastFetching,
    fetch,
  }
}


export default useForecast
