import React, { useState } from 'react'
import cx from 'classnames'
import { useStations, useZoom } from 'models/publicTransport'
import { useUserLocation } from 'models/mapLayer'
import { useDevice } from 'device'
import { useSwipeable } from 'react-swipeable'

import { UserLocationMarker } from 'components/map'
import NewMap, { NewMapProps } from 'compositions/map/NewMap/NewMap'
import FeedbackButton from 'compositions/FeedbackButton/FeedbackButton'

import useNavigation from './util/useNavigation'
import { Stations, RoadRoutes, Transport, RoadRoutesLegend } from './components'
import StationInfo from './components/Stations/StationInfo/StationInfo'

import s from './TransportMap.scss'


type TransportMapProps = {
  className?: string
  isRoadsActive: boolean
  mapHeight: number
  selectedTag?: PublicTransport.TransportTypeTitle | 'ALL'
  isUserMarkerWithTooltip?: boolean
  center?: [number, number]
  initialZoom?: number
  stationId?: string // if we pass stationId then we are displaying one station with bigger
  // icon than the other ones(see StationDetailsPage)
  withFeedback?: boolean
  isHomePage?: boolean
}

const TransportMap: React.FC<TransportMapProps & NewMapProps & Omit<EditableMap.MapProps, 'form'>> = (props) => {
  const { className, selectedTag = 'ALL', isRoadsActive, mapHeight, isUserMarkerWithTooltip, center,
    initialZoom = 14, stationId, withFeedback, isHomePage, ...rest } = props

  const { userLocation, isDone } = useUserLocation()
  const { zoom, center: zoomCenter, isBigIcons, updateZoom, updateCenter } = useZoom(initialZoom,  center || userLocation)

  const { stations, isStationsFetching } = useStations()
  const [ isExpanded, setExpanded ] = useState(false)
  const [ selectedStation, setSelectedStation ] = useState<string>(undefined)
  const [ isFullscreen, setIsFullscreen ] = useState<boolean>(false)
  const { filteredStations, searchField } = useNavigation(stations)

  const { isMobile } = useDevice()

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      console.log(eventData)
      setExpanded(eventData.dir === 'Up')
    },
    delta: { up: 20, down: 20 },
    preventScrollOnSwipe: true,
  })

  return (
    <div className={className}>
      <div className={cx('relative overflow-hidden')} style={{ height: `${mapHeight}px` }}>
        {
          <NewMap
            center={zoomCenter}
            zoom={zoom}
            onZoomEnd={({ zoom, center }) => {
              updateZoom(zoom)
              updateCenter(center)
            }}
            withFullScreen
            searchField={searchField}
            onFullScreenChange={setIsFullscreen}
            {...rest}
          >
            <Transport selectedTag={selectedTag} />
            {
              <Stations
                stations={filteredStations}
                isFiltered={filteredStations?.length < stations?.length}
                stationId={stationId}
                isSmallIcons={zoom < 16}
                setSelectedStation={setSelectedStation}
              />
            }
            {
              isRoadsActive && (
                <>
                  <RoadRoutes />
                  <RoadRoutesLegend />
                </>
              )
            }
            <UserLocationMarker userLocation={userLocation} isDone={isDone} withTooltip={isUserMarkerWithTooltip} />
          </NewMap>
        }
      </div>
      {
        (selectedStation && isMobile && isFullscreen) &&
          <div
            className={cx(
              "bg-white fixed w-full border-arrival",
              s.swipeableOpen, isExpanded && s.swipeableClose,
              { "scroll-y" : isExpanded }, 
            )}
            {...handlers}
          >
            <StationInfo
              className="p-16px h-full"
              stationId={selectedStation}
              closeModal={() => setSelectedStation(null)}
            />
          </div>
      }
      {
        withFeedback && (
          <FeedbackButton className="mt-8px" appealCategoryId={1014} />
        )
      }
      {
        (selectedStation && isMobile && !isFullscreen && isHomePage) &&
          <StationInfo
            className="p-16px"
            stationId={selectedStation}
          />
      }
    </div>
  )
}

export default TransportMap