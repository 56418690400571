import links from 'links'
import { useMutation } from 'hooks'
import { useCallback, useMemo } from 'react'

import { openSubmitFailedModal, openSubmitSucceededModal } from 'compositions/modals'

import { useStations, useStationForm } from '.'


const useStation = (id?: string) => {
  const { station, isStationsFetching } = useStations()
  const { form, mapForm } = useStationForm(station)

  const queryOptions = useMemo(() => ({
    url: '/transport/rest/bus/env/station',
  }), [])

  const [ submit, state ] = useMutation<{ status: number, error?: string }>(queryOptions)

  const [ remove, isRemoving ] = useMutation<{ status: number, error?: string }>({
    ...queryOptions,
    method: 'DELETE',
  })

  const handleRemove = useCallback(async (id) => {
    const { data, cacheStorage } = await remove({
      url: `/transport/rest/bus/env/station/${id}`,
    })

    cacheStorage.reset('/transport/rest/bus/env/station?expand=true')

    return {
      data,
    }
  }, [ remove ])

  const handleSubmit = useCallback(async () => {
    const { values: formValues, errors: formErrors } = await form.submit()
    const { values: mapValues, errors: mapErrors } = await mapForm.submit()

    if (formErrors || mapErrors) {
      return
    }

    const { placemarks } = mapValues
    const [ lat, lon ] = placemarks[0].geometry

    submit({
      url: `/transport/rest/bus/env/station`,
      body: { ...(id && { id }), ...formValues, lat, lon },
      method: id ? 'PUT' : 'POST',
    }).then((response) => {
      const { data, cacheStorage, errors } = response

      if (errors) {
        openSubmitFailedModal({
          title: 'Сетевая ошибка:',
          subTitle: errors,
          text: 'Повторите запрос позже',
        })
      } else {
        const { error } = data || {}

        if (error) {
          openSubmitFailedModal({
            title: `Ошибка ${id ? 'редактирования' : 'создания'} остановки:`,
            subTitle: error,
          })
        } else {
          openSubmitSucceededModal({
            title: `Остановка успешно ${id ? 'обновлена' : 'создана'}`,
            redirectLink: links.admin.transport.root + '?tab=Остановки',
            callback: () => {
              cacheStorage.reset('/transport/rest/bus/env/station?expand=true')
            },
          })
        }
      }
    })
  }, [ form, id, mapForm, submit ])

  return {
    form,
    mapForm,
    station,
    submit: handleSubmit,
    remove: handleRemove,
    isFetching: isStationsFetching,
    isRemoving,
    // isSubmitting,
  }
}


export default useStation
