import links, { getLinkWithParams } from 'links'
import React, { useMemo } from 'react'
import { useDevice } from 'device'
import cx from 'classnames'
import { Popup as LeafletPopup } from 'react-leaflet'

import Popup from 'compositions/map/MapContent/components/Popup/Popup'
import { ButtonBase } from 'components/inputs'
import { ScrollableContainer } from 'components/layout'

import StationInfo from '../StationInfo/StationInfo'

import { Marker } from 'layers/publicTransport/components'

import busIcon from './images/station.png'
import 'leaflet/dist/leaflet.css'
import s from './StationMarker.scss'


type StationMarkerProps = {
  className?: string
  title: string
  lat: number
  lon: number
  stationId: string
  isSmallIcon?: boolean
  setSelectedStation: (stationId: string) => void
}

const StationMarker: React.FC<StationMarkerProps> = (props) => {
  const { className, title, stationId, lat, lon, isSmallIcon, setSelectedStation } = props

  const { isMobile } = useDevice()

  const position = useMemo(() => ([ lat, lon ]), [ lat, lon ])
  const iconSize = useMemo(() => (isSmallIcon ? [ 12, 12 ] : [ 24, 24 ]), [ isSmallIcon ])
  const iconAnchor = useMemo(() => (isSmallIcon ? [ 6, 6 ] : [ 12, 12 ]), [ isSmallIcon ])

  return (
    <Marker
      className={className}
      position={position}
      icon={busIcon}
      iconSize={iconSize}
      iconAnchor={iconAnchor}
      popupAnchor={[ 117, 0 ]}
    >
      <Popup
        data={{
          type: 'simpleCard',
          title,
          subTitle: 'Остановка',
          to: getLinkWithParams(links.transport.stations.details, { stationId }),
        }}
        eventHandlers={{
          add: () => setSelectedStation(stationId),
          remove: () => setSelectedStation(null),
        }}
      />
    </Marker>
  )
}


export default StationMarker
